<template>
    <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">
                <slot></slot>
            </p>
        </header>
        <section class="modal-card-body">
            <div v-if="allowVideo">
                <div class="field has-addons is-flex mode-selector">
                    <p class="control">
                        <button class="button"
                                v-bind:class="{ 'is-active': mode === 'image' }"
                                v-on:click="mode = 'image'">
                            <span class="icon is-small">
                                <font-awesome-icon icon="image"></font-awesome-icon>
                            </span>
                            <span>Image only</span>
                        </button>
                    </p>
                    <p class="control">
                        <button class="button"
                                v-bind:class="{ 'is-active': mode === 'video' }"
                                v-on:click="mode = 'video'">
                            <span class="icon is-small">
                                <font-awesome-icon icon="video"></font-awesome-icon>
                            </span>
                            <span>Video plus Image</span>
                        </button>
                    </p>
                </div>

                <div v-if="mode === 'video'">
                    <b-field label="Video URL"
                             v-if="mode === 'video'">
                        <b-input v-model="videoUrl"
                                 v-on:input="processVideoUrl">
                        </b-input>
                    </b-field>

                    <div class="video-wrapper">
                        <div class="vimeo-wrapper"
                             v-if="video.provider === 'vimeo'">
                            <vimeo-player style="width: 100%;"
                                          ref="vimeo"
                                          v-bind:video-id="video.src">
                            </vimeo-player>
                        </div>
                    </div>

                    <div class="image-wrapper">
                        <b-field label="Video Cover"
                                 v-show="video.provider && video.src">
                            <b-upload drag-drop
                                      v-model="dropFile"
                                      v-bind:disabled="!video.provider || !video.src">
                                <section class="section">
                                    <div class="content has-text-centered">
                                        <p>
                                            <b-icon
                                                icon="upload"
                                                size="is-large">
                                            </b-icon>
                                        </p>
                                        <p>Drop your files here or click to upload</p>
                                    </div>
                                </section>
                            </b-upload>
                        </b-field>
                    </div>
                </div>

                <div class="image-wrapper"
                     v-if="mode === 'image'">
                    <b-field>
                        <b-upload drag-drop
                                  v-model="dropFile">
                            <section class="section">
                                <div class="content has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="upload"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>Drop your files here or click to upload</p>
                                </div>
                            </section>
                        </b-upload>
                    </b-field>
                </div>
            </div>

            <div class="image-wrapper"
                 v-else>
                <b-field>
                    <b-upload v-model="dropFile"
                              v-bind:multiple="multiple"
                              drag-drop>
                        <section class="section">
                            <div class="content has-text-centered">
                                <p>
                                    <b-icon
                                        icon="upload"
                                        size="is-large">
                                    </b-icon>
                                </p>
                                <p>Drop your files here or click to upload</p>
                            </div>
                        </section>
                    </b-upload>
                </b-field>
            </div>
        </section>
        <footer class="modal-card-foot">
            <button class="button" type="button"
                    v-on:click="$parent.close()">
                Cancel
            </button>
        </footer>
    </div>
</template>

<script>
    import urlParser from 'js-video-url-parser/lib/base'
    import 'js-video-url-parser/lib/provider/youtube'
    import 'js-video-url-parser/lib/provider/vimeo'

    export default {
        name: 'ModalSetImage',
        props: {
            allowVideo: {
                type: Boolean,
                default: false
            },

            file: {
                type: Object,
                required: true
            },

            video: {
                type: Object
            },

            multiple: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                loading: false,
                providerOptions: ['Vimeo'],

                mode: this.allowVideo ? 'video' : 'image',
                videoUrl: this.video ? this.video.src : '',
                dropFile: null
            }
        },
        computed: {},
        methods: {
            processVideoUrl() {
                let video = urlParser.parse(this.videoUrl)

                console.log(video)

                if (video) {
                    this.video.provider = video.provider.toLowerCase()
                    this.video.src = video.id
                } else {
                    this.video.provider = ''
                    this.video.src = ''
                }
            }
        },
        mounted() {
            if (
                this.video &&
                this.video.provider &&
                this.video.src
            ) {
                this.videoUrl = urlParser.create({
                    videoInfo: {
                        provider: this.video.provider,
                        id: this.video.src
                    },
                    format: 'long',
                    params: {}
                })
            }
        },
        watch: {
            'video'(newVal, oldVal) {
                this.$emit('update:video', newVal)
            },

            'dropFile'(newVal, oldVal) {
                this.$emit('update:file', newVal)
                if (this.mode === 'image') {
                    this.$emit('update:video', {
                        provider: '',
                        src: ''
                    })
                }
                this.$parent.close()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .modal-card-body {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .mode-selector {
            justify-content: center;
            margin-top: 0.75rem;

            .button {
                &.is-active {
                    background-color: #4a4a4a;
                    color: #fff;
                }
            }
        }

        .video-wrapper {
            width: 100%;
            margin-bottom: 1rem;

            & > .youtube-wrapper {

            }

            & > .vimeo-wrapper {

            }
        }

        .image-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .modal-card-foot {
        justify-content: flex-end;
    }
</style>
